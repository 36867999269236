<template>
  <div class="container-full step">
    <div class="container content">
      <div class="step-title">分站开通仅需6步，高效省心</div>
      <div>
        <div class="join-step">
          <div class="join-step-item">
            <div class="join-step-item-title">
              <span class="num">1</span>
              <span class="text">注册账号</span>
            </div>
            <div class="join-step-item-body">
              注册会员账号，若已有账号，直接在登录界面进行登录
            </div>
          </div>
          <div class="join-step-arrow">
            <img src="~assets/images/index/steparrow.png">
          </div>
          <div class="join-step-item">
            <div class="join-step-item-title">
              <span class="num">2</span>
              <span class="text">申请代理</span>
            </div>
            <div class="join-step-item-body">
              进入礼品商城，在“网站管理”点击“开通”申请分站
            </div>
          </div>
          <div class="join-step-arrow">
            <img src="~assets/images/index/steparrow.png">
          </div>
          <div class="join-step-item">
            <div class="join-step-item-title">
              <span class="num">3</span>
              <span class="text">支付费用</span>
            </div>
            <div class="join-step-item-body">
              按照操作流程，点击付费
            </div>
          </div>
        </div>
        <div class="join-step-two">
          <div class="join-step-two-arrow">
            <img src="~assets/images/join/arrowb.png">
          </div>
        </div>
        <div class="join-step">
          <div class="join-step-item">
            <div class="join-step-item-title">
              <span class="num">6</span>
              <span class="text">推广盈利</span>
            </div>
            <div class="join-step-item-body">
              对已上线的分站进行运营管理、推广管理
            </div>
          </div>
          <div class="join-step-arrow">
            <img src="~assets/images/index/steparrow.png">
          </div>
          <div class="join-step-item">
            <div class="join-step-item-title">
              <span class="num">5</span>
              <span class="text">开通成功</span>
            </div>
            <div class="join-step-item-body">
              系统审核通过后，分站即开通成功，可立即投入使用
            </div>
          </div>
          <div class="join-step-arrow">
            <img src="~assets/images/index/steparrow.png">
          </div>
          <div class="join-step-item">
            <div class="join-step-item-title">
              <span class="num">4</span>
              <span class="text">网站配置</span>
            </div>
            <div class="join-step-item-body">
              设置网站域名、网站名称、推广佣金，QQ号等信息，完成后点击“保存”,等待系统审核
            </div>
          </div>
        </div>
      </div>
      <div class="step-bottom">
        <div class="step-button" @click="activateNow">立即开通分站</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    activateNow () {
      this.$router.push('/oem/manage')
    }
  },
  mounted() {
    // 获取可视区域宽度
    const width = document.body.scrollWidth
    const app = document.querySelector('.step')
    // console.log(app.style)
    app.style.width = width + 'px'
  }
}
</script>

<style scoped src="styles/views/join/joinstep.css">
</style>
